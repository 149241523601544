.game {
  background-color: #fafafa;
  margin: 0 auto 0;
  padding: 10px 20px 0;
  max-width: 500px;
}

.game .board .row {
  display: flex;
  align-items: center;
}

.game .board .row .cell {
  width: 50px;
  height: 50px;
  font-size: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.game .board .row .result, .game .board .row button {
  margin-left: 20px;
}
